import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ErrorMessage from '../errorMessage'
import { useTranslation, Trans } from 'react-i18next';
import HukkaSelect from '../hukkaSelect'

var cron = require('cron-parser');

const Lander = () => {
  const { firstName, rou: readOnlyUser } = useSelector(state => state.user)
  const {
      restaurants,
      simpleinsight,
      monthSimpleinsight,
      forms,
      formTemplates,
      formRows
  } = useSelector(state => state)
  const { t } = useTranslation()
  const [selectedRestaurants, setSelectedRestaurants] = useState(null)
  const [selectedTime, setSelectedTime] = useState('week')


  const restaurantOptions = restaurants.slice()
  .sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  })
  .map(r => ({
    value: r.id,
    label: r.name,
  }))

  const userRestaurants = restaurants.map(r => r.id)

  const monitoringUncompleted = () => {
    let notComplete = false;

    forms.filter(f => userRestaurants.indexOf(f.restaurant) !== -1).forEach(function (form) {
      if (!notComplete && form.active) {
        let template = formTemplates.find(ft => ft.id === form.template)
        if (template.active) {
          let validCron = true
          let interval = null
          try {
            interval = cron.parseExpression(template.cron)
          } catch {
            validCron = false
          }

          if (validCron) {
            let nextDeadline = interval.next().toString()
            let deadlineUnix = moment(nextDeadline).unix()
            let gracePeriodUnix = moment(nextDeadline).subtract(template.gracePeriod, 'm').unix()
    
            if (moment().unix() < deadlineUnix && moment().unix() > gracePeriodUnix) {
              let validFormRows = formRows.filter(fr => fr.form === form.id && moment(fr.created).unix() < deadlineUnix && moment(fr.created).unix() > gracePeriodUnix)
              
              if (!validFormRows || validFormRows.length === 0) {
                notComplete = true
              } else {
                validFormRows.forEach(function (formRow) {
                  if (formRow.completed === false) {
                    notComplete = true
                  }
                })
              }
            }
          }
        }
      }
    })

    return notComplete
  }


  const insights = () => {
    let html = []
    let current = {
      co2: 0,
      totalWaste: 0,
      plateWaste: 0,
      servings: 0,
      zeroes: 0
    }
    let comparison = {
      co2: 0,
      totalWaste: 0,
      plateWaste: 0,
      servings: 0,
      zeroes: 0
    }

    let restaurantIds = selectedRestaurants && selectedRestaurants.map(sr => sr.value)
    let insightArr = []
    if (selectedTime === 'week') {
      insightArr = simpleinsight
    } else if (selectedTime === 'month') {
      insightArr = monthSimpleinsight
    }

    let restaurantValue = selectedRestaurants && selectedRestaurants.length === 1 ? selectedRestaurants[0].value : ''
    let lastWeekValue = moment().subtract(1, 'weeks').format('YYYY/W/')
    let shareActive = selectedRestaurants && selectedRestaurants.length === 1 && restaurants.find(r => r.id === selectedRestaurants[0].value).allowPublicInsights

    insightArr.forEach(function (arr) {
      if (arr.insights.length > 0 && (selectedRestaurants === null || restaurantIds.indexOf(arr.restaurant) !== -1)) {
        arr.insights.forEach(function (insight) {
          let key = Object.keys(insight)[0]
          if (key === 'activity') {
            current['servings'] += parseFloat(insight['activity']['currentServings'])
            comparison['servings'] += parseFloat(insight['activity']['comparisonServings'])
            current['zeroes'] += parseFloat(insight['activity']['currentZeroes'])
            comparison['zeroes'] += parseFloat(insight['activity']['comparisonZeroes'])
          } else {
            current[key] += parseFloat(insight[key].current)
            comparison[key] += parseFloat(insight[key].comparison)
          }
        })
      }
    })

    let shareable = false

    if (current.co2 > comparison.co2) {
      html.push(<div key={selectedTime+'1'} className="insight">
        <div className="insight__icon">
          <img id="co2-icon" src="/img/insights/co2-icon.png" alt="CO2" />
        </div>
        <div className="insight__number insight__number--red"><span>{Math.round((current.co2 - comparison.co2) / comparison.co2 * 100)} %</span></div>
        <div className="insight__text">{t('CO2 päästöjen määrä nousi')} {Math.round((current.co2 - comparison.co2) / comparison.co2 * 100)} %</div>
      </div>)
    } else if (current.co2 < comparison.co2) {
      shareable = true
      html.push(<div key={selectedTime+'1'} className="insight">
        <div className="insight__icon">
          <img id="co2-icon" src="/img/insights/co2-icon.png" alt="CO2" />
        </div>
        <div className="insight__number insight__number--green"><span>{Math.round((current.co2 - comparison.co2) / comparison.co2 * 100)} %</span></div>
        <div className="insight__text">{t('CO2 päästöjen määrä laski')} {Math.round((current.co2 - comparison.co2) / comparison.co2 * 100) * -1} %</div>
        {selectedRestaurants && selectedRestaurants.length === 1 && (
          <a target="_blank" href={'https://hukka.ai/' + t('hiilipaastot') + '/' + lastWeekValue + restaurantValue}>
            <button
              type="button"
              className={'button is-small is-secondary-background'}
              style={{color: shareActive ? '#fff' : '#ccc'}}
              disabled={!shareActive}
            >
              <Trans>Jaa</Trans>
            </button>
          </a>
        )}
      </div>)
    } else {
      html.push(<div key={selectedTime+'1'} className="insight">
        <div className="insight__icon">
          <img id="co2-icon" src="/img/insights/co2-icon.png" alt="CO2" />
        </div>
        <div className="insight__number"><span>0 %</span></div>
        <div className="insight__text">{t('CO2 päästöjen määrä pysyi samana')}</div>
      </div>)
    }

    if (current.totalWaste > comparison.totalWaste) {
      html.push(<div key={selectedTime+'2'} className="insight">
        <div className="insight__icon">
          <img id="waste-icon" src="/img/insights/waste-icon.png" alt="Kokonaishävikki" />
        </div>
        <div className="insight__number insight__number--red"><span>{Math.round((current.totalWaste - comparison.totalWaste) / comparison.totalWaste * 100)} %</span></div>
        <div className="insight__text">{t('Kokonaishävikin määrä nousi')} {Math.round((current.totalWaste - comparison.totalWaste) / comparison.totalWaste * 100)} %</div>
      </div>)
    } else if (current.totalWaste < comparison.totalWaste) {
      shareable = true
      html.push(<div key={selectedTime+'2'} className="insight">
        <div className="insight__icon">
          <img id="waste-icon" src="/img/insights/waste-icon.png" alt="Kokonaishävikki" />
        </div>
        <div className="insight__number insight__number--green"><span>{Math.round((current.totalWaste - comparison.totalWaste) / comparison.totalWaste * 100)} %</span></div>
        <div className="insight__text">{t('Kokonaishävikin määrä laski')} {Math.round((current.totalWaste - comparison.totalWaste) / comparison.totalWaste * 100) * -1} %</div>
        {selectedRestaurants && selectedRestaurants.length === 1 && (
          <a target="_blank" href={'https://hukka.ai/' + t('ruokahavikki') + '/' + lastWeekValue + restaurantValue}>
            <button
              type="button"
              className={'button is-small is-secondary-background'}
              style={{color: shareActive ? '#fff' : '#ccc'}}
              disabled={!shareActive}
            >
              <Trans>Jaa</Trans>
            </button>
          </a>
        )}
      </div>)
    } else {
      html.push(<div key={selectedTime+'2'} className="insight">
        <div className="insight__icon">
          <img id="waste-icon" src="/img/insights/waste-icon.png" alt="Kokonaishävikki" />
        </div>
        <div className="insight__number"><span>0 %</span></div>
        <div className="insight__text">{t('Kokonaishävikin määrä pysyi samana')}</div>
      </div>)
    }

    if (current.plateWaste > comparison.plateWaste) {
      html.push(<div key={selectedTime+'3'} className="insight">
        <div className="insight__icon">
          <img id="platewaste-icon" src="/img/insights/platewaste-icon.png" alt="Lautashävikki" />
        </div>
        <div className="insight__number insight__number--red"><span>{Math.round((current.plateWaste - comparison.plateWaste) / comparison.plateWaste * 100)} %</span></div>
        <div className="insight__text">{t('Lautashävikin määrä nousi')} {Math.round((current.plateWaste - comparison.plateWaste) / comparison.plateWaste * 100)} %</div>
      </div>)
    } else if (current.plateWaste < comparison.plateWaste) {
      shareable = true
      html.push(<div key={selectedTime+'3'} className="insight">
        <div className="insight__icon">
          <img id="platewaste-icon" src="/img/insights/platewaste-icon.png" alt="Lautashävikki" />
        </div>
        <div className="insight__number insight__number--green"><span>{Math.round((current.plateWaste - comparison.plateWaste) / comparison.plateWaste * 100)} %</span></div>
        <div className="insight__text">{t('Lautashävikin määrä laski')} {Math.round((current.plateWaste - comparison.plateWaste) / comparison.plateWaste * 100) * -1} %</div>
        {selectedRestaurants && selectedRestaurants.length === 1 && (
          <a target="_blank" href={'https://hukka.ai/' + t('lautashavikki') + '/' + lastWeekValue + restaurantValue}>
            <button
              type="button"
              className={'button is-small is-secondary-background'}
              style={{color: shareActive ? '#fff' : '#ccc'}}
              disabled={!shareActive}
            >
              <Trans>Jaa</Trans>
            </button>
          </a>
        )}
      </div>)
    } else {
      html.push(<div key={selectedTime+'3'} className="insight">
        <div className="insight__icon">
          <img id="platewaste-icon" src="/img/insights/platewaste-icon.png" alt="Lautashävikki" />
        </div>
        <div className="insight__number"><span>0 %</span></div>
        <div className="insight__text">{t('Lautashävikin määrä pysyi samana')}</div>
      </div>)
    }

    html.push(<div key={selectedTime+'logs-0'} style={{display: 'block'}}>
      <div className="insight">
        <div className="insight__icon" style={{width: '220px', textAlign: 'left'}}>
          <b>{t('Kirjaus')} % {selectedTime === 'week' ? (t('Viikko') + ' ' + moment().subtract(1, 'week').isoWeek()) : moment().subtract(1, 'month').format('MMMM')}</b>
        </div>
        <div className="insight__number"><span>{current.servings > 0 ? Math.round((current.servings - current.zeroes) / current.servings * 100) : 0} %</span></div>
        <div className="insight__text">{t('Kirjauksia tehty')} {current.servings > 0 ? Math.round((current.servings - current.zeroes) / current.servings * 100) : 0} %<br />({current.servings - current.zeroes}/{current.servings})</div>
      </div>
    </div>)

    return (<div>
    { selectedTime === 'week' && (
      <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{marginBottom: '0'}}>{t('Viikko')} {moment().subtract(1, 'week').isoWeek()} ({t('Edellinen viikko')}) {t('vs')} {t('Viikko')} {moment().subtract(2, 'week').isoWeek()}</h2>
    )}
    { selectedTime === 'month' && (
      <h2 className="title is-2 is-size-4-mobile is-uppercase" style={{marginBottom: '0'}}>{moment().subtract(1, 'month').format('MMMM')} ({t('Edellinen kuukausi')}) {t('vs')} {moment().subtract(2, 'month').format('MMMM')}</h2>
    )}
      <Link to="/insights">
        <button
          type="button"
          className={'button is-small is-secondary-background'}
          style={{color: 'white'}}
        >
          <Trans>Tarkastele</Trans>
        </button>
      </Link>
      <br />
      <br />
      {selectedRestaurants && selectedRestaurants.length === 1 && !shareActive && shareable && (
        <p>{t('Ottaaksesi jako-toiminnon käyttöön, kytke ravintolan "Julkiset insightit"-asetus päälle. Asetus löytyy sivulta "Profiilit & ravintolat" -> "Ravintolat" -> "Muokkaa" halutun ravintolan kohdalla.')}</p>
      )}
      <br />
      {html}
    </div>)
  }

  return (
    <>
    { readOnlyUser !== true && readOnlyUser !== 1  && (
      <div className="view-login container">
        <h1 className="title is-1 is-size-3-mobile is-uppercase">
          {t('Tervetuloa') + ' ' + firstName}
        </h1>
        <div style={{ marginTop: '20px' }}>
          <Link to="/add-waste">
            <button
              type="button"
              className="button is-large is-fullwidth is-primary"
            >
              <Trans>Lisää hävikki</Trans>
            </button>
          </Link>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Link to="/scale-view">
            <button
              type="button"
              className="button is-large is-fullwidth is-primary"
            >
              <Trans>Vaakanäkymä</Trans>
            </button>
          </Link>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Link to="/waste-report">
            <button
              type="button"
              className="button is-large is-fullwidth is-primary"
            >
              <Trans>Hävikkiseuranta</Trans>
            </button>
          </Link>
        </div>
        <div style={{ marginTop: '20px' }}>
          {monitoringUncompleted() && (
            <ErrorMessage msg={t('Puuttuvia kirjauksia omavalvonnassa')} />
          )}
          <Link to="/monitoring">
            <button
              type="button"
              className="button is-large is-fullwidth is-primary"
            >
              <Trans>Omavalvonta</Trans>
            </button>
          </Link>
        </div>
        {restaurants && simpleinsight && monthSimpleinsight && (
          <div>
            <br />
            <br />
            <button
              type="button"
              className={'button is-small' + (selectedTime === 'week' ? ' is-primary-background' : '')}
              style={{margin: '5px'}}
              onClick={() => {
                setSelectedTime('week')
              }}
            >
              <Trans>Viikko</Trans>
            </button>
            <button
              type="button"
              className={'button is-small' + (selectedTime === 'month' ? ' is-primary-background' : '')}
              style={{margin: '5px'}}
              onClick={() => {
                setSelectedTime('month')
              }}
            >
              <Trans>Kuukausi</Trans>
            </button>
            <br />
            <br />
            <HukkaSelect
              options={restaurantOptions}
              value={selectedRestaurants}
              isMulti
              placeholder={t('Valitse ravintolat')}
              onChange={e => {
                setSelectedRestaurants(e)
              }}
            />
            {insights()}
          </div>
        )}
        {!simpleinsight && (
          <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
        )}
      </div>
    )}
    </>
  )
}

export default Lander
